<template>

<div class="page" :class="{'is-desktop': window.is.desktop, 'is-tablet': window.is.tablet, 'is-mobile': window.is.mobile, 'is-device': window.is.tablet || window.is.mobile}">

	<com-head />

	<com-body>

		<slot></slot>

	</com-body>

	<com-foot />

</div>

</template>

<script>

export default {

	components: {

		'com-head': () => import('./page/Head'),
		'com-foot': () => import('./page/Foot'),
		'com-body': () => import('./page/Body')

	}

}

</script>

<style>

.page {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: calc(100vh - var(--vh-offset, 0px));
}

</style>
